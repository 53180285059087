<template>
    <div class="city_box1">
        <div class="title">》{{title_in}}《</div>
        <div class="city_img_box">
            <img style="width: 100%;height: 100%"  :src="img_in">
        </div>
    </div>
</template>

<script>
    export default {
        name: "versea_city_com",
        props: {
            title_in: {type: String},
            img_in: {type: String}
        }
    }
</script>

<style scoped>

    .city_box1 {
        height: 600px;
        width: calc(600px * 1.4);
        background: url("../assets/image/versea/versea_city_bg.png") no-repeat;
        background-size: 100% 100%;
    }

    .title {
        color: #00e5ff;
        font-size: 30px;
        font-weight: bold;
        text-align: center;
        padding: 40px;
    }

    .city_img_box {
        text-align: center;
        width: 80%;
        height: 76%;
        margin: 0 auto;
        border:2px solid #00e5ff;
        box-shadow: darkgrey 0px 0px 5px 3px ;
    }

</style>